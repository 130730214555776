<template>
  <div>
    <b-row>
      <!-- <b-col>
              <b-form-input
                type="number"
                placeholder="Enter Network Type"
                v-model="networkInput.type"
              ></b-form-input>
          </b-col> -->
      <b-row class="col-md-12">
        <b-col class="col-md-3 col-12 mt-1">
          <b-form-input type="text" placeholder="Enter Network Title" v-model="networkInput.title"
            required></b-form-input>
        </b-col>
        <b-col class="col-md-3 col-12 mt-1">
          <multiselect :options="countryOpt" label="name" track-by="name" v-model="selectedCountry"
            placeholder="Select Country" required>
          </multiselect>
        </b-col>
        <b-col class="col-md-3 col-12 mt-1">
          <button class="col-md-6 col-12 custom-font-18 custom-btn" @click="ActionNetwork()">{{ editNetworkType ? "Edit" :
            "Add" }}</button>
        </b-col>

        <b-col class="col-md-3 text-right mt-2">
          <b-form-group label-cols="4" label="Per page" class="custom-font-16 md-0">
            <b-form-select class="col-md-10 border" v-model="perPage" :options="pageOptions">
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

    </b-row>
    <b-row class="mt-2">
      <b-col>
        <b-table :fields="networkFields" striped hover :items="networkTypeData" stacked="md" :filter="filter"
          :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
          <template #cell(action)="row">
            <button size="sm" @click="editNetwork(row.item)" class="btn text-blue btn-xs mr-1">
              <feather type="edit"></feather>
            </button>
            <button size="sm" @click="deleteNetwork(row.item)" class="btn text-danger btn-xs">
              <feather type="trash-2"></feather>
            </button>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-col class="mt-3 p-0">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0 custom-font-16"></b-pagination>
    </b-col>
  </div>
</template>



<script>
import NetworkTypeService from "../../../../services/NetworkTypeService";
import Multiselect from "vue-multiselect";

export default {
  name: "NetworkType",
  components: {
    Multiselect,
  },
  data() {
    return {
      countryOpt: [],
      selectedCountry: [],
      networkTypeData: [],
      networkFields: [
        // { key: "type", label: "Type" },
        { key: "title", label: "Title" },
        { key: "country", label: "Country" },
        { key: "action", label: "Action" },
      ],
      networkInput: {
        type: "",
        title: "",
      },
      editNetworkType: false,
      totalRows: 0,
      filter: null,
      currentPage: 1,
      perPage: 20,
      pageOptions: [20, 50, 100, 200],
    };
  },
  mounted() {
    this.getNetworkType();
    this.getCountry();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getCountry() {
      let response = await NetworkTypeService.getCountry();
      this.countryOpt = [];
      response.data.forEach((element) => {
        this.countryOpt.push({ id: element.alpha_3, name: element.name });
      });
    },
    async getNetworkType() {
      this.networkTypeModel = true;
      let response = await NetworkTypeService.getNetworkType();
      if (response.result) {
        this.networkTypeData = response.data;
        this.totalRows = response.data.length;
      }
    },
    async deleteNetwork(data) {
      let payload = {
        nid: data._id,
      };
      if (confirm("Are you sure you want to delete")) {
        let response = await NetworkTypeService.removeNetworkType(payload);
        this.getNetworkType();
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {
          theme: "outline",
          position: "bottom-center",
          type: variant,
          duration: 2000,
        });
      }
    },
    editNetwork(data) {
      this.editNetworkType = true;
      this.networkInput = data;
      this.selectedCountry = this.countryOpt.find(
        (element) => element.id === data.country
      );
    },
    async ActionNetwork() {
      if (this.validate()) {
        if (this.editNetworkType) {
          let payload = {
            nid: this.networkInput._id,
            updateObj: {
              type: this.networkInput.type,
              title: this.networkInput.title,
              country: this.selectedCountry.id,
            },
          };
          let response = await NetworkTypeService.updateNetworkType(payload);
          this.getNetworkType();
          let variant = response.result ? "success" : "error";
          this.$toasted.show(response.message, {
            theme: "outline",
            position: "bottom-center",
            type: variant,
            duration: 2000,
          });
        } else {
          let country = this.selectedCountry.id;
          this.networkInput.country = country;
          let response = await NetworkTypeService.addNetworkType(
            this.networkInput
          );
          this.getNetworkType();
          let variant = response.result ? "success" : "error";
          this.$toasted.show(response.message, {
            theme: "outline",
            position: "bottom-center",
            type: variant,
            duration: 2000,
          });
        }
      }
      this.editNetworkType = false;
      this.selectedCountry = [];
      this.networkInput = { type: "", title: "" };
    },
    validate() {
      const showToast = (message) => {
        this.$toasted.show(message, { theme: 'outline', position: 'bottom-center', type: 'error', duration: 2000 });
      };
      if (this.networkInput.title == null || this.networkInput.title == '') {
        showToast('Please enter network title');
        return false;
      }
      if (this.selectedCountry.id == null || this.selectedCountry.id == '' || this.selectedCountry.length == 0) {
        showToast('Please select country');
        return false;
      }
      return true;
    }
  },
};
</script>