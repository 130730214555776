<template>
  <div>
    <b-row>
      <b-col class="col-md-6 col-12">
        <Breadcrumbs main="" title="Transactions" class="showBreadcrumbs"/>
      </b-col>
    </b-row>
    <!-- Card starts -->
    <transcationdetail></transcationdetail>

    <!-- Filters starts -->
    <b-row class="m-0">
      <b-col class="col-md-2 col-12">
        <b-form-group
          label="Sort By"
          label-for="sort-by-select"
          v-slot="{ ariaDescribedby }"
        >
          <div class="d-flex align-items-center">
            <b-form-select
              id="sort-by-select"
              v-model="sortBy"
              :options="sortOptions"
              :aria-describedby="ariaDescribedby"
              class="border mr-2 w-60 custom-font-22"
            >
              <template #first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              :disabled="!sortBy"
              :aria-describedby="ariaDescribedby"
              @input="filterTrxn"
              class="border w-40 custom-font-22"
            >
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </div>
        </b-form-group>
      </b-col>

      <b-col class="col-md-2 col-12">
        <b-form-group label="Filter by Country">
          <multiselect
            :options="countryOpt"
            label="name"
            track-by="name"
            v-model="selectedCountry"
            placeholder="Select Country"
            @input="changeNetworkType"
            @remove="removeNetworkType"
          >
          </multiselect>
        </b-form-group>
      </b-col>
      <b-col class="col-md-2 col-12">
        <b-form-group label="Filter by Network">
          <multiselect
            :options="networkTypeArray"
            label="name"
            track-by="id"
            v-model="selectedNetworkType"
            placeholder="Select Network"
          >
          </multiselect>
        </b-form-group>
      </b-col>


      <b-col class="col-md-6 col-12 text-right d-flex justify-content-end align-items-center" >
        <button class="btn custom-btn" style="margin-top: 0.7vh;" @click="filterTrxn"
          >Filter</button
        >
        <button class="btn custom-btn ml-2" style="margin-top: 0.7vh;" @click="init()"
          >Reset</button
        >
          
        <button class="btn custom-btn ml-2" style="margin-top: 0.7vh;"  @click="open_import_transaction = true"
          >Bulk Import Transactions</button
        >
      </b-col>
    </b-row>

    <!-- Filter Ends -->

    <b-row class="mt-2">
      <b-col xl="12" md="12">
        <b-card title="" type="gradient-red" sub-title="" >
          <b-row>
            <b-col class="col-md-6 col-12">
              <span class="custome-title">Transactions</span>
            </b-col>
            <b-col class="text-right col-md-6 col-12 btn-container">
              <button class="btn mr-2 mb-2 custom-btn-2" @click="addTrx()">
                Add Transaction </button>
              <button class="btn mb-2 custom-btn" @click="networkTypeModel = true">
              Add Network Type </button>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col class="col-md-5 col-12 badge-container">
              <span class="badge badge-success p-2 custom-font-20">Approved <span class="ml-1 custom-font-24">{{ counter.approved }}</span> </span>
              <span class="badge badge-gray p-2 custom-font-20">Pending <span class="ml-1 custom-font-24">{{ counter.pending }}</span> </span>
              <span class="badge badge-danger p-2 custom-font-20">Rejected <span class="ml-1 custom-font-24">{{ counter.rejected }}</span> </span>
            </b-col>

            <b-col class="col-md-3 col-12 mt-2">
              <div>
                <div slot="with-padding" class="row">
                    <div>
                      <div class="media custom-font-18 media-container">
                        <label class="m-5">UnMapped Services</label>
                        <div class="media-body icon-state ml-1">
                          <label class="switch f-14">
                            <input type="checkbox" v-model="isUnMappedService" @change="filterTrxn"><span class="switch-state"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </b-col>

            <b-col class="text-right col-md-4 col-12">
            <b-form-group 
              label-cols="9" 
              label="Per page" 
              class="mb-0 custom-font-18"
            >
              <b-form-select
                class="col-md-12 border custom-font-24"
                v-model="perPage"
                :options="pageOptions"
                @change="filterTrxn()"
              ></b-form-select>
            </b-form-group>
          </b-col>
          </b-row>

          <!-- Table starts -->
          <b-row class="table-responsive datatable-vue">
            <b-table
              class="text-left custom-font-16 custom-table-style"
              :fields="trxFields"
              striped
              hover
              :items="trxData"
              :busy="isBusy"
              stacked="md"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
            >
          
              <template #cell(_id)="row">
                <b class="text-blue cust_text" style="cursor: pointer;" @click.prevent="openTrxDetail(row.item)"
                  >{{ row.item._id }}
                </b>
              </template>
              <template #cell(landing)="row">  
                  <img
                    :src="getImageLanding(row.item._id)"
                    alt="landing"
                    class="img_custom_style"
                    @click="openImageModal(getImageLanding(row.item._id))"
                    @error="$event.target.src = `../no_image.png`"
                  />
              </template>
              <template #cell(sid)="row">
               
                <span v-if="serviceList.find((e) => e._id == row.item.sid)">
                  {{ serviceList.find((e) => e._id == row.item.sid).title }}
                </span>
              </template>

              <template #cell(channel)="row">
                <span v-if="chennelArray.find((e) => e.id == row.item.channel)">
                  {{ chennelArray.find((e) => e.id == row.item.channel).name }}
                </span>
              </template>
              <template #cell(netType)="row">
                {{ row.item.networkTypeArray }}
                <span
                  v-if="networkTypeArray.find((e) => e.id == row.item.netType)"
                >
                  {{
                    networkTypeArray.find((e) => e.id == row.item.netType).name
                  }}
                </span>
              </template>

              <!-- <template #cell(status)="row">
                  <div class="media-body icon-state switch-outline">
                        <label class="switch">
                        <input type="checkbox" :checked=row.item.status @change="changeStatus(row.item)">
                        <span class="switch-state" :class="row.item.status?'bg-success':'bg-danger'"></span>
                        </label>
                  </div>
              </template> -->
              <template #cell(country)="row">
                <!-- {{ row.item.country }} -->
                <span
                  v-if="countryOpt.filter((t) => t.id == row.item.country)[0]"
                >
                  {{
                    countryOpt.filter((t) => t.id == row.item.country)[0]?.name
                  }}
                  {{ " [" + row.item.country + "]" }}
                </span>
              </template>
              <template #cell(isApproved)="row">
                <div class="media-body icon-state switch-outline">
                  <label class="switch">
                    <input
                      type="checkbox"
                      :checked="row.item.isApproved"
                      @change="changeApproval(row.item)"
                    />
                    <span
                      class="switch-state"
                      :class="row.item.isApproved ? 'bg-success' : 'bg-danger'"
                    ></span>
                  </label>
                </div>
              </template>
              <template #cell(actions)="row">
                <button
                  size="sm"
                  @click="openTrxDetail(row.item)"
                  class="btn text-blue btn-xs mr-1"
                >
                  <feather type="edit"></feather>
                </button>
                <button
                  size="sm"
                  @click="deleteTrxDetail(row.item)"
                  class="btn text-danger btn-xs"
                >
                  <feather type="trash-2"></feather>
                </button>
              </template>
              <template #table-busy>
                <div class="col-md-12" >
                  <h6 class="sub-title mb-0 text-center">Loading...</h6>
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
                </div> 
              </template>
            </b-table>
          </b-row>
          <b-col class="mt-3 p-0 text-right">
            <div class="custom-pagination">
              <a v-show="currentPage != 1" @click="filterTrxn('prev')"
                >&laquo; Back</a
              >
              <a class="active">{{ currentPage }}</a>
              <a v-show="trxData.length >= 1" @click="filterTrxn('next')"
                >Next &raquo;</a
              >
            </div>
          </b-col>
        </b-card>
      </b-col>
    </b-row>

    <!-- Bulk Transaction Import Start  -->
    <b-modal
      id="import-monitor"
      size="xl"
      title="Import bulk transaction"
      class="theme-modal"
      v-model="open_import_transaction"
      no-close-on-backdrop
      hide-footer
    >
      <input type="file" id="files" accept=".csv" ref="trxfile" />
      <span v-if="loading">
        <h6 class="sub-title mb-0 text-center">Uploading...</h6>
        <div class="loader-box">
          <div class="loader-30"></div>
        </div>
      </span>
      <span v-else>
        <b-button
          size="sm"
          variant="success"
          class="btn-pill"
          @click="UploadCsv()"
          >Upload</b-button
        >
      </span>
      <div class="p-3">
        <b-row class="mt-2">
          <b-col class="col-md-12">
            <div class="f-20 f-w-600 link_txt" @click="downloadCSV">
              Download file template
            </div>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer>
        <b-button @click="open_import_transaction = false" color="danger"
          >Close</b-button
        >
      </template>
    </b-modal>
    <!-- Bulk Transaction Import End  -->

    <!-- Network Type Modal Start  -->
    <b-modal
      id="networktype"
      size="xl"
      title="Network Type"
      class="theme-modal"
      v-model="networkTypeModel"
      no-close-on-backdrop
      hide-footer
    >
      <network-type-model> </network-type-model>
    </b-modal>
    <!-- Network Type Modal End  -->
    <b-container fluid class="mt--7">
      <b-row class="justify-content-center">
        <b-col lg="12"> </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import TranscationService from "../../../../services/TranscationService";
import transcationdetail from "./TranscationDetail.vue";
import ServiceInfoApi from "../../../../services/ServiceInfo";
import config from "../../../../services/globalLit";
import SampleCsv from "../../../../services/sampleCsvData";
import NetworkTypeService from "../../../../services/NetworkTypeService";
import NetworkTypeModel from "./NetworkType.vue";
import Multiselect from "vue-multiselect";

export default {
  components: {
    transcationdetail,
    NetworkTypeModel,
    Multiselect,
  },
  mounted() {
    console.log("trx mounted");
    this.$root.$on("trxlist", () => {
      this.filterTrxn();
    });
    this.userinfo = JSON.parse(window.localStorage.getItem("userinfo"));

    // if (this.userRole != "manager") {
    //   this.$router.push("/login");
    // }
    this.filterTrxn();
    this.getCountry();
    this.getCounter();
  },
  data() {
    return {
      userinfo: {},
      showImage: false,
      pageOptions: [50, 100, 200, 300, 500],
      perPage: 50,
      currentPage: 1,
      isBusy: false,
      chennelArray: config.channelTypeArray,
      networkTypeArray: [],
      trxFields: [
        { key: "_id", label: "ID", sortable: true },
        {
          key: "landing",
          label: "Landing",
          sortable: true,},
        {
          key: "sid",
          label: "Service",
        },

        {
          key: "channel",
          label: "Channel",
          sortable: true,
        },
        {
          key: "netType",
          label: "Network Type",
        },
        {
          key: "country",
          label: "Country",
          sortable: true,
        },
        // {
        //   key: "status",
        //   label: "Status",
        // },
        {
          key: "isApproved",
          label: "Approved",
          sortable: true,
        },
        {
          key: "createdAt",
          label: "Time",
          sortable: true,
          formatter: (value) => {
            // Options to format the date in DD/MM/YYYY HH:mm format
            const options = {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            };
            return new Date(value).toLocaleString("en-IN", options);
          },
          class: "cust-table-field",
        },
        {
          key: "actions",
          label: "Action",
        },
      ],
      trxData: [],
      serviceList: [],
      open_import_transaction: false,
      sampleData: [],
      sampleTrxfields: [],
      loading: false,
      networkTypeModel: false,
      countryOpt: [],
      selectedCountry: [],
      selectedNetworkType: [],
      sortBy: "_id",
      sortDesc: true,
      sortDirection: "desc",
      isUnMappedService: false,
      counter: {
        approved: 0,
        pending: 0,
        rejected: 0,
      }
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.trxFields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  methods: {
    async removeNetworkType() {
      this.selectedCountry = [];
      await this.changeNetworkType();
    },
    async changeNetworkType() {
      console.log(this.selectedCountry);
      try {
        await this.getNetworkType(this.selectedCountry.id);
      } catch (error) {
        console.log("error in getting net type");
        console.log(error);
      }
    },
    async openTrxDetail(data) {
      // console.log("openTrxDetail");
    
      let landings = await TranscationService.getLandings(data._id);
      if(landings.result){
        this.$root.$emit("trxdetail", Object.assign(data, landings.data));
        this.$bvModal.show("openTrxDetail");
      }
    },
    addTrx() {
      this.$root.$emit("trxdetail", false);
      this.$bvModal.show("openTrxDetail");
    },
    async deleteTrxDetail(data) {
      let payload = {
        trxid: data._id,
      };
      if (confirm("Are you sure you want to delete")) {
        let response = await TranscationService.remove(payload);
        this.filterTrxn();
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {
          theme: "outline",
          position: "bottom-center",
          type: variant,
          duration: 2000,
        });
      }
    },
    async changeStatus(data) {
      if (data.status == false) {
        data.status = true;
      } else {
        data.status = false;
      }
      let payload = {
        trxid: data._id,
        changeBody: {
          status: data.status,
        },
      };
      let response = await TranscationService.update(payload);
      let variant = response.result ? "success" : "error";
      this.$toasted.show(response.message, {
        theme: "outline",
        position: "bottom-center",
        type: variant,
        duration: 2000,
      });
    },
    async changeApproval(data) {
      if (data.isApproved == false) {
        data.isApproved = true;
      } else {
        data.isApproved = false;
      }
      let payload = {
        trxid: data._id,
        changeBody: {
          isApproved: data.isApproved,
        },
      };
      let response = await TranscationService.update(payload);
      let variant = response.result ? "success" : "error";
      this.$toasted.show(response.message, {
        theme: "outline",
        position: "bottom-center",
        type: variant,
        duration: 2000,
      });
    },
    async init() {
      var payload = "?page=" + this.currentPage + "&limit=" + this.perPage;
      this.selectedCountry = [];
      this.isBusy = true;
      console.log("init called");
      await this.getNetworkType();
      let [response, serviceData] = await Promise.all([
        TranscationService.get(payload),
        ServiceInfoApi.get(),
      ]);
      if (serviceData.result) {
        this.serviceList = serviceData.data;
      }
      if (response.result) {
        this.trxData = response.data;
      }
      this.isBusy = false;
    },
    async getNetworkType(country) {
      let response = await NetworkTypeService.getAllNetworkType(country);
      let networkTypeArray = [];
      for (const [key, value] of Object.entries(response.data)) {
        networkTypeArray.push({ id: key, name: value });
      }
      this.networkTypeArray = networkTypeArray;
    },
    async UploadCsv() {
      this.loading = true;
      if (this.$refs.trxfile.files[0]) {
        var file = this.$refs.trxfile.files[0];
        const formData = new FormData();
        formData.append("trxcsv", file);
        let response = await TranscationService.csvUpload(formData);
        if (response.result) {
          this.$toasted.show(response.message, {
            theme: "outline",
            position: "bottom-center",
            type: "success",
            duration: 2000,
          });
          this.open_import_transaction = false;
          this.filterTrxn();
        } else {
          this.$toasted.show(response.message, {
            theme: "outline",
            position: "bottom-center",
            type: "error",
            duration: 2000,
          });
        }
      } else {
        this.$toasted.show("Please select file", {
          theme: "outline",
          position: "bottom-center",
          type: "error",
          duration: 2000,
        });
      }
      this.loading = false;
    },
    downloadCSV() {
      this.sampleTrxfields = SampleCsv.sampleTrxfields;
      const data = [this.sampleTrxfields];
      const csvContent =
        "data:text/csv;charset=utf-8," +
        data.map((e) => e.join(",")).join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "TemplateBulkImportFile.csv");
      document.body.appendChild(link);
      link.click();
    },
    downloadSampleData() {
      this.sampleData = SampleCsv.sampleTrxData;
      config.downloadCSV(this.sampleData);
    },
    async getCountry() {
      let response = await NetworkTypeService.getCountry();
      this.countryOpt = [];
      response.data.forEach((element) => {
        this.countryOpt.push({ id: element.alpha_3, name: element.name });
      });
    },
     openImageModal(data) {
      // window.open('', '_blank', `width=${screen.width},height=${screen.height}`).document.write(`<html><head><title>Image Popup</title></head><body><img src="${data}" style="max-width: 100%; height: auto;"></body></html>`);
      const popupWindow = window.open('', '_blank', `width=${screen.width},height=${screen.height}`);
      popupWindow.document.write(`<html><head><title>Image Popup</title></head><body><img src="${data}" style="max-width: 100%; height: auto;"></body></html>`);
      popupWindow.document.addEventListener('keydown', function(event) {
      if (event.key === 'Escape' || event.keyCode === 27) {
        popupWindow.close();
      }
    });
    },
    async filterTrxn(txt, sid) {
      if (txt == "next") {
        this.currentPage++;
      }
      if (txt == "prev") {
        if (this.currentPage != 1) {
          this.currentPage--;
        }
      }
      this.isBusy = true;

      let options = {
        page: this.currentPage || "",
        limit: this.perPage || "",
        sort: this.sortBy || "",
        order: this.sortDesc ? "desc" : "asc",
      };
      if (this.selectedCountry.id) {
        options.country = this.selectedCountry.id;
      }
      if (this.selectedNetworkType.id) {
        options.nettype = this.selectedNetworkType.id;
      }
      if (this.isUnMappedService) {
        options.unmappedservice = this.isUnMappedService;
      }

      // let response = await TranscationService.getFilteredTrx(options);

       let [response, serviceData, networkType] = await Promise.all([
        TranscationService.getFilteredTrx(options),
        ServiceInfoApi.get(),
        this.getNetworkType()
      ]);
      if (serviceData.result) {
        this.serviceList = serviceData.data;
      }

      if (response.result) {
        this.trxData = response.data;
      }
      this.isBusy = false;
    },
    getImageLanding(id) {
      return config.getLandingImages + `/${id}`;
    },
    async getCounter() {
      let response = await TranscationService.getCounter();
      if (response.result) {
        this.counter.approved = response.data.counter.approved;
        this.counter.pending = response.data.counter.pending;
        this.counter.rejected = response.data.counter.rejected;
      }
    }
  },
};
</script>
<style>
.img_custom_style {
  min-height: 25px;
  min-width: 15px;
  max-height: 150px;
  max-width: 100px;
  border-radius: 10px 10px 10px 10px;
}
.img_custom_style:hover {
  cursor: pointer;
  border: 2px solid blue;
}

</style>
<style scoped>
.showBreadcrumbs {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .custom-font-18,
  .custom-font-20,
  .custom-font-22,
  .custom-font-24 {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 991px) {
  .showBreadcrumbs {
    display: block !important;
  }
}
</style>
