<template>
  <div>
    <b-modal id="openTrxDetail" title="Transaction" size="xl">
      <b-card>
        <div class="text-right" style="display: inline-block">
          <span
            class="btn btn-outline-primary btn-xs p-1"
            @click="add_service_modal = true"
            >Create Services</span
          >
          <span
            class="btn btn-outline-primary btn-xs ml-2 p-1"
            @click="spModal = true"
            >Create Service Provider</span
          >
          <span
            class="btn btn-outline-primary btn-xs ml-2 p-1"
            @click="smModal = true"
            >Service Mapper</span
          >
        </div>
        <b-row>
          <b-col>
            <label class="">Select Service <span style="color: red">*</span> </label>
            <multiselect
              class="border-0"
              :options="serviceList"
              label="title"
              v-model="selectedService"
              placeholder="Select Service"
            >
            </multiselect>
          </b-col>
          <b-col>
            <label class=""> Channel <span style="color: red">*</span> </label>
            <multiselect
              class="border-0"
              :options="chennelArray"
              label="name"
              v-model="selectedChannel"
              placeholder="Select Channel"
            >
            </multiselect>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <label class="mt-2 ">Country <span style="color: red">*</span> </label>
            <multiselect
              class="border-0"
              :options="countryOpt"
              label="name"
              track-by="name"
              v-model="selectedCountry"
              placeholder="Select Country"
              @input="changeNetworkType"
              @remove="removeNetworkType"
            >
            </multiselect>
          </b-col>
          <b-col>
            <label class="mt-2 ">Network Type <span style="color: red">*</span> </label>
            <multiselect
              class="border-0"
              :options="networkTypeArray"
              label="name"
              track-by="id"
              v-model="selectedNetworkType"
              placeholder="Select Network Type"
            >
            </multiselect>
          </b-col>
        </b-row>
        <hr class="my-4" />
        <h6 class="heading-small text-muted mb-4">Urls information</h6>

        <div class="">
          <b-row>
            <b-col md="12">
              <label>Banner Found URL</label>
              <b-input
                type="text"
                label="Banner Found URL"
                placeholder="Banner Found Url"
                v-model="trxData.bannerFoundUrl"
              >
              </b-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
                <label class="mt-2">Ad Url <span style="color: red">*</span> </label>
            </b-col>
            <b-col>
                <b-input
                    type="text"
                    label=""
                    placeholder="Ad Url"
                    v-model="trxData.adUrl"
                >
                </b-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <label class="mt-2">Final URL <span style="color: red">*</span> </label>
              <b-input
                type="text"
                label="Final URL"
                placeholder="Final Url"
                v-model="trxData.finalUrl"
              >
              </b-input>
            </b-col>
          </b-row>
          <hr class="my-4" />
          <h6 class="heading-small text-muted mb-4">Screenshot Upload</h6>
        </div>

        <div class="">
          <b-row>
            <b-col md="12">
              <b-form-file
                accept="image/*"
                class="m-2"
                type="file"
                label="Banner"
                placeholder="Banner Image"
                @change="bannerFileChange($event.target.files)"
              >
              </b-form-file>

              <img
                :src="bannerImg"
                alt="banner"
                class="img_custom_style"
                @click="openImageModal(bannerImg)"
                @error="$event.target.src = `../no_image.png`"
              />
              <!-- <div
                v-b-modal.modal-1
                :style="
                  'min-height: 100px;height:auto;width:180px; background:url(' +
                  bannerImg +
                  '); background-size:contain; box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; border-radius: 10px 10px 10px 10px;background-repeat: no-repeat;display: inline-block;'
                "
              ></div> -->
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-file
                accept="image/*"
                class="m-2"
                type="file"
                label="Ad"
                placeholder="Ad Image"
                @change="adFileChange($event.target.files)"
              >
              </b-form-file>
              <img
                :src="adImg"
                alt="add"
                class="img_custom_style"
                @click="openImageModal(adImg)"
                @error="$event.target.src = `../no_image.png`"
              />
              <!-- <div
                v-b-modal.modal-1
                :style="
                  'height:200px;width:180px; background:url(' +
                  adImg +
                  '); background-size:cover; box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; border-radius: 10px 10px 10px 10px'
                "
              ></div> -->
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
            

              <b-row>
                <b-col md="3"  v-for="(image, index) in images" :key="index" >
                  <b-form-group>
                    <b-form-file
                      accept="image/*"
                      class="m-2"
                      type="file"
                      label="Landing "
                      multiple
                      placeholder="Landing Image"
                      @change="previewImage($event.target.files, index)"
                    >
                    </b-form-file>

                    <b-img
                      :id="'img_' + index"
                      class="img_custom_style"
                      @click="openImageModal(imagePreviewUrls[index])"
                      :src="imagePreviewUrls[index]"
                      @error="$event.target.src = `../no_image.png`"
                    ></b-img>
                    <b-button
                      @click="removeImage(index)"
                      variant="danger"
                      v-if="images.length > 1"
                      >Remove</b-button
                    >
                  </b-form-group>
                 
                  <!-- <b-button @click="submitForm" variant="success">Submit</b-button> -->
                </b-col>
                
                
              </b-row>
               <b-button
                    @click="addImage"
                    variant="primary"
                    v-if="images.length < landingImgLimit"
                    >Add More Landing Image</b-button
                  >

              <!-- <div v-for="(image, index) in landingImgs" :key="index">
                <img :src="image" alt="landing" class="img_custom_style" @click="openImageModal(image)" @error="$event.target.src=(`../no_image.png`);">
               </div> -->

              <!-- <img :src="landingImg" alt="landing" class="img_custom_style" @click="openImageModal(landingImg)" @error="$event.target.src=(`../no_image.png`);"> -->
              <!-- <div
                v-b-modal.modal-1
                :style="
                  'height:200px;width:180px; background:url(' +
                  landingImg +
                  '); background-size:cover; box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; border-radius: 10px 10px 10px 10px'
                "
              ></div> -->
            </b-col>
          </b-row>

          <hr class="my-4" />
          <h6 class="heading-small text-muted mb-4">Network Logs</h6>
        </div>

        <div class="">
          <b-card no-body>
            <b-tabs pills card>
              <b-tab title="Hosts" active>
                <b-card-text>
                  <span v-if="hosts.length >= 0">
                    <li v-for="(item, key) in hosts" :key="key">
                      {{ item }} -
                      <span
                        class="link_txt text-danger"
                        @click="deleteHost(key)"
                        >Delete</span
                      >
                    </li>
                  </span>
                </b-card-text>
                <b-row>
                  <span class="mt-2 text-secondary" style="cursor: pointer;">
                    <feather type="info" title="Example: xyz.com, abc.in"></feather>
                  </span>
                  <b-col class="col-md-9">
                    <b-form-input
                      type="text"
                      label="Hosts"
                      placeholder="Please enter comma separated hosts"
                      v-model="InputHost"
                      v-on:keyup.enter="AddHosts()"
                    ></b-form-input>
                  </b-col>
                  <b-col>
                    <b-button variant="primary" @click="AddHosts()"
                      >Add Host</b-button
                    >
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="URL Chain">
                <b-card-text>
                  <span v-if="urlChain.length >= 0">
                    <li v-for="(item, key) in urlChain" :key="key">
                      {{ item }} -
                      <span
                        class="link_txt text-danger"
                        @click="deleteUrlChain(key)"
                        >Delete</span
                      >
                    </li>
                  </span>
                </b-card-text>
                <b-row>
                  <span class="mt-2 text-secondary" style="cursor: pointer;">
                    <feather type="info" title="Example: https://xyz.com, http://abc.in"></feather>
                  </span>
                  <b-col class="col-md-9">
                    <b-input
                      type="text"
                      label="URL Chain"
                      placeholder="Please enter comma separated urls"
                      v-model="InputUrlChain"
                      v-on:keyup.enter="AddUrlChain()"
                    >
                    </b-input>
                  </b-col>
                  <b-col>
                    <b-button variant="primary" @click="AddUrlChain()"
                      >Add URL</b-button
                    >
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="Log">
                <b-card-text>
                  <span v-if="logs.length >= 0">
                    <li v-for="(item, key) in logs" :key="key">
                      {{ item }} -
                      <span
                        class="link_txt text-danger"
                        @click="deleteLogs(key)"
                        >Delete</span
                      >
                    </li>
                  </span>
                </b-card-text>
                <b-row>
                  <b-col class="col-md-10">
                    <b-input
                      type="text"
                      label="Logs"
                      placeholder="input logs to add"
                      v-model="InputLogs"
                      v-on:keyup.enter="AddLogs()"
                    >
                    </b-input>
                  </b-col>
                  <b-col>
                    <b-button variant="primary" @click="AddLogs()"
                      >Add Logs</b-button
                    >
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
        <hr class="my-2" />
        <b-row>
          <b-col>
            <label class="mt-2">is Approved ?</label>
            <div class="media-body icon-state switch-outline">
              <label class="switch">
                <input
                  type="checkbox"
                  :checked="isApproved"
                  @change="changeApproval(isApproved)"
                />
                <span
                  class="switch-state"
                  :class="isApproved ? 'bg-success' : 'bg-danger'"
                ></span>
              </label>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <template #modal-footer>
        <div>
          <b-button
            variant="primary"
            class="float-right mr-2"
            @click="changeTrxDetail()"
            :disabled="disable_submit"
          >
            {{ isEdit ? "Edit" : "Add" }}
          </b-button>
          <b-button class="float-right mr-2" @click="closeModal()">
            Close
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- Service Modal -->
    <b-modal
      v-model="add_service_modal"
      id="openSPDetail"
      title="Service Provider"
      class="theme-modal"
      @hidden="init()"
      size="xl"
      no-close-on-backdrop
      hide-footer
    >
      <serdetail
        :serviceModal="add_service_modal"
        @closeSdDetail="add_service_modal = false"
      ></serdetail>
    </b-modal>
    <!-- End Service provider Modal -->

    <!-- Service provider Modal -->
    <b-modal
      v-model="spModal"
      id="openSPDetail"
      title="Service Provider"
      class="theme-modal"
      size="xl"
      no-close-on-backdrop
      hide-footer
    >
      <spdetail :spModal="spModal" @closeSPDetail="spModal = false"></spdetail>
    </b-modal>

    <!-- Service Modal -->
    <b-modal
      v-model="smModal"
      id="openServiceMapper"
      title="Service Provider"
      class="theme-modal"
      size="xl"
      no-close-on-backdrop
      hide-footer
    >
      <service-mapper></service-mapper>
    </b-modal>
    <!-- End Service provider Modal -->
  </div>
</template>

<script>
import TrxServices from "../../../../services/TranscationService";
import ServiceInfoApi from "../../../../services/ServiceInfo";
import NetworkTypeService from "../../../../services/NetworkTypeService";
import Multiselect from "vue-multiselect";
import serdetail from "../serviceinfo/AddServiceInfo.vue";
import spdetail from "../serviceprovider/AddServiceProvider.vue";
import config from "../../../../services/globalLit";
import ServiceMapper from "../serviceMapping.vue";
export default {
  components: {
    Multiselect,
    serdetail,
    spdetail,
    ServiceMapper,
  },
  data() {
    return {
      landingImgLimit: 12,
      imagePreviewUrls: [],
      images: [null],
      img: "",
      spModal: false,
      smModal: false,
      add_service_modal: false,
      disable_submit: false,
      bannerImg: "",
      landingImg: "",
      landingImgs: [],
      imageOptions: [],
      imageValue: [],
      adImg: "",
      screenshot: {},
      selectedNetworkType: {},
      chennelArray: config.channelTypeArray,
      networkTypeArray: [],
      selectedChannel: {},
      selectedService: {},
      serviceList: [],
      trxData: {},

      isApproved: false,
      isEdit: false,
      urlChain: "",
      hosts: [],
      logs: "",
      countryOpt: [],
      selectedCountry: [],
      InputHost: "",
      InputUrlChain: "",
      InputLogs: "",
    };
  },
  mounted() {
    this.init();

    this.$root.$on("trxdetail", async (data) => {
      this.imagePreviewUrls = [];
      this.images = [null];
      if (data) {
        this.trxData = data;
        this.isEdit = true;
        // this.urlChain = data.urlChain.join(",");
        this.urlChain = data.urlChain.filter(
          (item) => item !== null && item !== ""
        );
        // this.logs = data.logs.join(",");
        this.logs = data.logs.filter((item) => item !== null && item !== "");
        // this.hosts = data.hosts.join(",");
        this.hosts = data.hosts.filter((item) => item !== null && item !== "");
        this.isApproved = data.isApproved;
        this.selectedService = this.serviceList.find((e) => data.sid == e._id);
        this.selectedChannel = config.channelTypeArray.find(
          (e) => data.channel == e.id
        );
        this.selectedNetworkType = this.networkTypeArray.find(
          (e) => data.netType == e.id
        );
        this.selectedCountry = this.countryOpt.find(
          (e) => data.country == e.id
        );
        this.bannerImg = this.getImageBanner(data._id);
        this.adImg = this.getImageAdd(data._id);
        this.landingImgs = data.landingImgUrl;

        let promises = [];
        for (let i = 0; i < data.landingImgUrl.length; i++) {
          promises.push(this.imageUrlToBase64(data.landingImgUrl[i]));
        }
        this.imagePreviewUrls = await Promise.all(promises);
        this.images = data.landingImgUrl.map((item, index) => {
          return null;
        });

        this.imageOptions = data.landingImgUrl.map((item, index) => {
          return { title: `Image ${index + 1}`, img: item };
        });
        // if (data.banner) {
        //   // this.bannerImg = this.arrayBufferToBase64(data.banner.data);
        // }
        // if (data.ad) {
        //   this.adImg = this.arrayBufferToBase64(data.ad.data);
        // }
        // if (data.landing) {
        //   this.landingImg = this.arrayBufferToBase64(data.landing.data);
        // }
      } else {
        this.isEdit = false;
        (this.bannerImg = ""),
          (this.landingImg = ""),
          (this.adImg = ""),
          (this.trxData = {});
        this.selectedService = [];
        this.selectedNetworkType = [];
        this.selectedChannel = [];
        this.selectedCountry = [];
        this.isApproved = false;
        this.hosts = [];
        this.urlChain = [];
        this.logs = [];
      }
    });
  },
  methods: {
    async removeNetworkType() {
      this.selectedCountry = [];
      await this.changeNetworkType();
    },
    async changeNetworkType() {
      console.log(this.selectedCountry);
      try {
        await this.getNetworkType(this.selectedCountry.id);
      } catch (error) {
        console.log("error in getting net type");
        console.log(error);
      }
    },
    async imageUrlToBase64(url) {
      const data = await fetch(url);
      const blob = await data.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
        reader.onerror = reject;
      });
    },

    addImage() {
      this.images.push(null);
    },
    removeImage(index) {
      this.images.splice(index, 1);
      this.imagePreviewUrls.splice(index, 1);
    },
    previewImage(f, index) {
      const file = f[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imagePreviewUrls.splice(index, 1, reader.result);
      };
    },
    getImageLanding(id) {
      return config.getLandingImages + `/${id}`;
    },
    getImageBanner(id) {
      return config.getBannerImages + `/${id}`;
    },
    getImageAdd(id) {
      return config.getAddImages + `/${id}`;
    },
    async init() {
      await this.getNetworkType();
      await this.getCountry();
      let response = await ServiceInfoApi.get();
      if (response.result) {
        this.serviceList = response.data.reverse();
      }
    },
    async getNetworkType(country) {
      let response = await NetworkTypeService.getAllNetworkType(country);
      let networkTypeArray = [];
      for (const [key, value] of Object.entries(response.data)) {
        networkTypeArray.push({ id: key, name: value });
      }
      this.networkTypeArray = networkTypeArray;
    },
    async getCountry() {
      let response = await NetworkTypeService.getCountry();
      this.countryOpt = [];
      response.data.forEach((element) => {
        this.countryOpt.push({ id: element.alpha_3, name: element.name });
      });
    },
    closeModal() {
      this.$root.$emit("trxlist", {});
      this.$bvModal.hide("openTrxDetail");
    },
    async changeTrxDetail() {
      if (this.validate()) {
        if (this.isEdit) {
          await this.editTrxData();
        } else {
          await this.addTrxData();
        }
      }
    },
    async editTrxData() {
      this.disable_submit = true;
      let payload = {
        trxid: this.trxData._id,
        changeBody: {
          netType: this.selectedNetworkType["id"],
          channel: this.selectedChannel["id"],
          country: this.selectedCountry["id"],
          isApproved: this.isApproved,
          banner: this.screenshot.banner,
          ad: this.screenshot.ad,
          landing: this.imagePreviewUrls,

          finalUrl: this.trxData.finalUrl,
          bannerFoundUrl: this.trxData.bannerFoundUrl,
          adUrl: this.trxData.adUrl,

          // hosts: this.hosts.split(","),
          hosts: this.hosts,
          // urlChain: this.urlChain.split(","),
          urlChain: this.urlChain,
          // logs: this.logs.split(",")
          logs: this.logs,
        },
      };

      if (this.selectedService) {
        payload.changeBody.sid = this.selectedService._id;
      }

      let response = await TrxServices.update(payload);
      let variant = response.result ? "success" : "error";
      this.$toasted.show(response.message, {
        theme: "outline",
        position: "bottom-center",
        type: variant,
        duration: 1000,
        onComplete: () => {
          this.$root.$emit("trxlist", {});
          this.$bvModal.hide("openTrxDetail");
          this.disable_submit = false;
        },
      });
    },
    async addTrxData() {
      this.disable_submit = true;
      this.trxData.sid = this.selectedService._id;
      (this.trxData.netType = this.selectedNetworkType["id"]),
        (this.trxData.channel = this.selectedChannel["id"]),
        // (this.trxData.hosts = this.hosts.split(","));
        (this.trxData.hosts = this.hosts);
      this.trxData.country = this.selectedCountry["id"];
      // this.trxData.urlChain = this.urlChain.split(",");
      this.trxData.urlChain = this.urlChain;
      // this.trxData.logs = this.logs.split(",");
      this.trxData.logs = this.logs;
      this.trxData.banner = this.screenshot.banner;
      this.trxData.ad = this.screenshot.ad;
      this.trxData.landing = this.imagePreviewUrls;
      this.trxData.isApproved = this.isApproved;

      let response = await TrxServices.add(this.trxData);
      let variant = response.result ? "success" : "error";
      this.$toasted.show(response.message, {
        theme: "outline",
        position: "bottom-center",
        type: variant,
        duration: 2000,
        onComplete: () => {
          this.$root.$emit("trxlist", {});
          this.$bvModal.hide("openTrxDetail");
          this.disable_submit = false;
        },
      });
    },
    bannerFileChange(f) {
      const file = f[0];

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.bannerImg = reader.result;
        this.screenshot.banner = reader.result;
      };
    },
    adFileChange(f) {
      const file = f[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.adImg = reader.result;
        this.screenshot.ad = reader.result;
      };
    },
    arrayBufferToBase64(buffer) {
      var binary = "";
      var bytes = new Uint8Array(buffer);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return binary;
    },
    landingFileChange(f) {
      this.landingImgs = [];
      this.screenshot.landingImgs = [];
      for (let i = 0; i < f.length; i++) {
        const file = f[i];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.landingImgs.push(reader.result);
          this.screenshot.landingImgs.push(reader.result);
        };
      }
    },
    openImageModal(data) {
      // window.open('', '_blank', `width=${screen.width},height=${screen.height}`).document.write(`<html><head><title>Image Popup</title></head><body><img src="${data}" style="max-width: 100%; height: auto;"></body></html>`);
      const popupWindow = window.open(
        "",
        "_blank",
        `width=${screen.width},height=${screen.height}`
      );
      popupWindow.document.write(
        `<html><head><title>Image Popup</title></head><body><img src="${data}" style="max-width: 100%; height: auto;"></body></html>`
      );
      popupWindow.document.addEventListener("keydown", function (event) {
        if (event.key === "Escape" || event.keyCode === 27) {
          popupWindow.close();
        }
      });
    },
    async changeApproval(data) {
      if (data == false) {
        this.isApproved = true;
      } else {
        this.isApproved = false;
      }
    },
    AddHosts() {
      if (this.InputHost != "") {
        this.hosts = [...this.InputHost.split(",")];
        this.InputHost = "";
      }
    },
    deleteHost(key) {
      if (confirm("Are you sure to delete this host?")) {
        this.hosts.splice(key, 1);
      }
    },
    AddUrlChain() {
      let urls = this.InputUrlChain.split(",");
      urls.forEach((url) => {
        if (config.isValidUrl(url) == false) {
          this.$toasted.show("Please enter valid url", {
            theme: "outline",
            position: "bottom-center",
            type: "error",
            duration: 2000,
          });
          return;
        }
      });

      if (this.InputUrlChain != "") {
        this.urlChain = [...this.InputUrlChain.split(",")];
        this.InputUrlChain = "";
      }
    },
    deleteUrlChain(key) {
      if (confirm("Are you sure to delete this url?")) {
        this.urlChain.splice(key, 1);
      }
    },
    AddLogs() {
      if (this.InputLogs != "") {
        this.logs.push(this.InputLogs);
        this.InputLogs = "";
      }
    },
    deleteLogs(key) {
      if (confirm("Are you sure to delete this log?")) {
        this.logs.splice(key, 1);
      }
    },
    validate() {
      const showToast = (message) => {
        this.$toasted.show(message, {
          theme: "outline",
          position: "bottom-center",
          type: "error",
          duration: 2000,
        });
      };

      if (this.selectedService && this.selectedService.length == 0) {
        showToast("Please select service");
        return false;
      }
      if (this.selectedChannel && this.selectedChannel.length == 0) {
        showToast("Please select channel");
        return false;
      }
      if (this.selectedChannel && this.selectedNetworkType.length == 0) {
        showToast("Please select network type");
        return false;
      }
      if (this.selectedChannel && this.selectedCountry.length == 0) {
        showToast("Please select country");
        return false;
      }

      // temporary removed
      // if(this.trxData.bannerFoundUrl == null || this.trxData.bannerFoundUrl == '' || config.isValidUrl(this.trxData.bannerFoundUrl) == false){
      //   showToast("Please enter valid banner found url");
      //   return false;
      // }

      if (
        this.trxData.finalUrl == null ||
        this.trxData.finalUrl == "" ||
        config.isValidUrl(this.trxData.finalUrl) == false
      ) {
        showToast("Please enter valid final url");
        return false;
      }

      if(this.trxData.adUrl == '' || config.isValidUrl(this.trxData.adUrl) == false){
        showToast('Please enter valid url');
        return false;
      }
      // if(this.landingImg == null || this.landingImg == ''){
      //   showToast("Please upload landing image");
      //   return false;
      // }
      if (this.hosts.length == 0) {
        showToast("Please enter hosts");
        return false;
      }
      if (this.urlChain.length == 0) {
        showToast("Please enter url chain");
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
.hidden_header {
  display: none;
}
div {
  margin: auto 0;
  width: 100%;
}
.img_custom_style {
  min-height: 200px;
  min-width: 180px;
  max-height: 250px;
  max-width: 250px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: 3px solid;
}
</style>